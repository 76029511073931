import { FaqStyle, Questoes } from './styles/Faq.style';
import CabecalhoInfo from '../componentes/cabecalhoInfo';
import CabecalhoMenu from '../componentes/cabecalhoMenu';
import Rodape from '../componentes/rodape';
import TituloPagina from '../componentes/tituloPagina';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileCircleQuestion, faPlus } from '@fortawesome/free-solid-svg-icons';
import numeros from '../dados/numeros.json';
import IrAoTopoWidget from '../componentes/irAoTopoWidget';
import SeoHelmet from '../componentes/seoHelmet';

const Faq = () => {
    return (
        <>
            <SeoHelmet 
                conector=' - '
                subtitulo='Dúvidas Frequentes'
                linkcanonical='faq'
            />
            <CabecalhoInfo />
            <CabecalhoMenu />

            <TituloPagina titulo='FAQ'
                          h1ClassName='center'
                          hiddenClassName='hidden'
                          link='FAQ'
                          info=''
                          getStyle={() => {}}
            />

            <FaqStyle>
                <p>CASO SUA RESPOSTA NÃO ESTEJA AQUI, ENVIE-NOS UMA MENSAGEM</p>
                <h2>QUESTÕES FREQUENTES</h2>
                <span><FontAwesomeIcon icon={faFileCircleQuestion} color='#27921A' fontSize='24px' /></span>

                <Questoes>
                    <div className='questao'>
                        <h3>Pagamento</h3>
                        <div className='color-rule'></div>

                        <input type='checkbox' id='1,1'></input>
                        <label htmlFor='1,1'><h4>1. Quais são as formas de pagamento?</h4> <FontAwesomeIcon icon={faPlus} color='#6F8190' fontSize='20px' /></label>
                        <div className='resposta'>
                            <p><b>Aceitamos</b> pagamento parcelado dos principais cartões de crédito e pagamento à vista no PIX ou boleto. Escolha um plano, execute o pagamento e comece a estudar agora mesmo.
                            <b>Não aceitamos</b> TED e <b>não aceitamos</b> parcelamento através de boleto.
                            </p>
                        </div>

                        <input type='checkbox' id='1,2'></input>
                        <label htmlFor='1,2'><h4>2. O que é Eduzz?</h4> <FontAwesomeIcon icon={faPlus} color='#6F8190' fontSize='20px' /></label>
                        <div className='resposta'>
                            <p>
                                A Eduzz oferece meios de pagamento (semelhante como o PayPal, o PagSeguro e outros), além de outros serviços agregados à plataforma (tal como nossa área de estudos, o <b>Nutror</b>).
                            </p>
                        </div>

                        <input type='checkbox' id='1,3'></input>
                        <label htmlFor='1,3'><h4>3. Como funciona o cancelamento?</h4> <FontAwesomeIcon icon={faPlus} color='#6F8190' fontSize='20px' /></label>
                        <div className='resposta'>
                            <p>
                                O cancelamento pode ser feito em até 15 DIAS <b>corridos</b> após a aquisição. O período normal é de 07 dias corridos, conforme previsto no CDC (Código de Defesa do Consumidor). Acrescentamos mais 08 dias a este período, totalizando 15 dias corridos. Basta entrar em contato através do e-mail <b>{numeros.email}</b> ou do whatsapp <b>{numeros.whatsapp}</b>.
                            </p>
                        </div>

                        <input type='checkbox' id='1,4'></input>
                        <label htmlFor='1,4'><h4>4. Posso parcelar meu pagamento?</h4> <FontAwesomeIcon icon={faPlus} color='#6F8190' fontSize='20px' /></label>
                        <div className='resposta'>
                            <p>
                                Sim, mas é <b>opcional</b>. Você pode parcelar qualquer plano em até 12x (ou seja, 2x, 3x etc.). Porém, a operação é ÚNICA, independente do parcelamento (após o período de 15 dias, não poderá mais ser cancelada).
                            </p>
                        </div>



                        <input type='checkbox' id='1,5'></input>
                        <label htmlFor='1,5'><h4>5. Posso cancelar o plano no 2º, 3º, 4º, ... mês?</h4> <FontAwesomeIcon icon={faPlus} color='#6F8190' fontSize='20px' /></label>
                        <div className='resposta'>
                            <p>
                                Depois de 15 DIAS corridos, não é possível cancelar, suspender etc. Os valores dos pacotes são calculados para dar o maior desconto e o melhor custo-benefício. Cancelar no meio do plano inviabiliza o modelo atual de preços cobrados e a previsão de investimentos para garantir o serviço. O período de 15 dias deve ser usado para testar a plataforma e decidir <b>dentro do período</b>.
                            </p>
                        </div>

                        <input type='checkbox' id='1,6'></input>
                        <label htmlFor='1,6'><h4>6. Mas, qual o valor mensal?</h4> <FontAwesomeIcon icon={faPlus} color='#6F8190' fontSize='20px' /></label>
                        <div className='resposta'>
                            <p>
                                Não existe "valor mensal". Existe o valor TOTAL de cada plano e a possibilidade de dividir este valor em parcelas. No ato do pagamento, é informado o <b>valor total</b> e dada a opção de parcelar. Parcelar ou não o valor total é opcional.
                            </p>
                        </div>

                        <input type='checkbox' id='1,7'></input>
                        <label htmlFor='1,7'><h4>7. Como é feita a renovação?</h4> <FontAwesomeIcon icon={faPlus} color='#6F8190' fontSize='20px' /></label>
                        <div className='resposta'>
                            <p>
                                <b>Não fazemos renovação automática</b>. Uma vez finalizado o período do seu plano (Anual, Bi-Anual e Tri-Anual), o acesso à plataforma é bloqueado e nenhuma outra cobrança é feita. <strong>Caso queira renovar</strong>, basta executar o mesmo procedimento de compra novamente.
                            </p>
                        </div>                        
                        <input type='checkbox' id='1,8'></input>
                        <label htmlFor='1,8'><h4>8. Posso pagar sem usar o limite do cartão?</h4> <FontAwesomeIcon icon={faPlus} color='#6F8190' fontSize='20px' /></label>
                        <div className='resposta'>
                            <p>
                            Esta funcionalidade está configurada na plataforma, mas é totalmente automatizada. Portanto, continua sendo uma única operação no cartão. Assim, se houver limite disponível, ele será consumido; caso contrário, a plataforma identificará isso e agendará os pagamentos mensalmente (é automático). Ressaltamos que, como se trata de uma única operação, o cancelamento não será possível após o período de análise previsto.
                            </p>
                        </div>
                       
                        <input type='checkbox' id='1,9'></input>
                        <label htmlFor='1,9'><h4>9. Posso comprar um módulo avulso?</h4> <FontAwesomeIcon icon={faPlus} color='#6F8190' fontSize='20px' /></label>
                        <div className='resposta'>
                            <p>
                            <b>Não</b>. Desde janeiro de 2019, a plataforma foi reestruturada para gerenciar acesso individual à área de membros única. Nela todos os módulos são disponibilizados para alunos autenticados. É inviável criar a mesma estrutura exclusiva para liberar acesso a apenas um módulo avulso (o custo é o mesmo), uma vez que todos os módulos compartilham a mesma área de membros (o Nutror). 
                            </p>
                        </div>

                        <input type='checkbox' id='1,10'></input>
                        <label htmlFor='1,10'><h4>10. Posso adquirir um plano não listado?</h4> <FontAwesomeIcon icon={faPlus} color='#6F8190' fontSize='20px' /></label>
                        <div className='resposta'>
                            <p>
                            <b>Não</b>. Os planos disponíveis estão listados na página de assinatura. Por razõres parecidas às informadas acima. Para acessar toda a plataforma por um mês, três meses etc., a estrutura seria a mesma que temos para liberar acesso por períodos maiores (e mesmo custo). Os planos atuais refletem a evolução em que mantivemos os valores antigos, mas aumentamos o período de acesso. Em outras palavras, hoje o valor para acessar um mês, três meses ou um ano, seriam os valores dos planos Anual, Bi-Anual e Tri-Anual, respectivamente. 
                            </p>
                        </div>
                    </div>
                        
                    <div className='questao'>
                        <h3>Vídeos, Materiais e Certificados</h3>
                        <div className='color-rule'></div>

                        <input type='checkbox' id='2,2'></input>
                        <label htmlFor='2,2'><h4>1. Como estudo pela Plataforma?</h4> <FontAwesomeIcon icon={faPlus} color='#6F8190' fontSize='20px' /></label>
                        <div className='resposta'>
                            <p>
                                Use o <strong>Provas de TI </strong> para: 1. planejar seu estudo diário; 2. buscar por assuntos e palavras-chave; 3. fazer download de slides. Use o <strong>Nutror</strong> para: 1. assistir aos vídeos; 2. emitir seus certificados. Veja detalhes no vídeo abaixo do Prof. Luis Claudio:
                            </p>
                            <iframe title='video' src="https://player.vimeo.com/video/457344614" width="480" height="270" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                        </div>

                        <input type='checkbox' id='2,3'></input>
                        <label htmlFor='2,3'><h4>2. O que é o Nutror?</h4> <FontAwesomeIcon icon={faPlus} color='#6F8190' fontSize='20px' /></label>
                        <div className='resposta'>
                            <p>É uma <b>área de membros</b> onde os alunos têm acesso aos curso. O foco do Nutror é ser um player de vídeo seguro. Vá direto ao Nutror se já souber que curso irá estudar. Caso contrário, comece seu planejamento de estudos usando as ferramentas do Provas de TI, como os <b>Planos de Estudo</b> etc.</p>
                        </div>

                        <input type='checkbox' id='2,4'></input>
                        <label htmlFor='2,4'><h4>3. Os professores interagem na plataforma?</h4> <FontAwesomeIcon icon={faPlus} color='#6F8190' fontSize='20px' /></label>
                        <div className='resposta'>
                            <p>
                                Não. Os vídeos são gravados para estudo individual sem interação com o professor. Os valores dos planos foram concebidos para tornar a plataforma mais acessível. Professores de plantão para dúvidas geralmente têm valor adicional (mentoria, coaching etc.).
                            </p>
                        </div>

                        <input type='checkbox' id='2,5'></input>
                        <label htmlFor='2,5'><h4>4. Os vídeos são disponibilizados em que formato?</h4> <FontAwesomeIcon icon={faPlus} color='#6F8190' fontSize='20px' /></label>
                        <div className='resposta'>
                            <p>
                                Os vídeos são disponibilizados em mp4 HD para celular ou desktop. O app Nutror também está disponível para Apple e Android. <b>No celular</b>, é possível baixar os vídeos para assistir em modo off-line. <b>No desktop</b>, não é possível fazer download. 
                            </p>
                        </div>

                        {/* Ativar isso com novo texto quando tiver custos em PDF também 
                        <input type='checkbox' id='2,7'></input>
                        <label htmlFor='2,7'><h4>5. Há materiais de estudo em PDF?</h4> <FontAwesomeIcon icon={faPlus} color='#6F8190' fontSize='20px' /></label>
                        <div className='resposta'>
                            <p>
                                Sim. 
                                No momento (Jun.23) está em andamento o projeto criação de outros materiais de apoio em PDF, a saber: 
                                <br></br><br></br>A - Transcrição consolidada da fala do professor;
                                <br></br>B - Resumo teórico dos módulos; e 
                                <br></br>C - Mapa mental dos módulos. 
                                <br></br><br></br>                                
                                Como são centenas de módulos, com novos sempre sendo lançados, não há prazo para finalizar este processo (ele continuará sempre).
                                Os módulos mais acessados nos Planos de Estudo estão sendo finalizados primeiro.
                                Vários já possuem este material de apoio e outros sendo adicionados diariamente pela equipe na aba <b>"Arquivos"</b> da aula <b>"Como Estudar pela Plataforma"</b>.
                                <br></br>
                                <br></br>
                                <img src='assets/images/materiais-em-pdf.jpg' width="480" height="270" alt='Conteúdos Novos'></img>                               
                            </p>
                        </div>
                        */}

                        <input type='checkbox' id='2,8'></input>
                        <label htmlFor='2,8'><h4>5. Qual é o formato do certificado?</h4> <FontAwesomeIcon icon={faPlus} color='#6F8190' fontSize='20px' /></label>
                        <div className='resposta'>
                            <p>
                                Os certificados são disponibilizados automaticamente pela plataforma. Após o sistema detectar 75% de aulas acessadas em um Curso, o botão "Imprimir Certificado" aparecerá. Basta clicar e o certificado será gerado para download (com <b>dados pré-definidos</b>).
                            </p>
                            <iframe title='video' src="https://player.vimeo.com/video/391815253" width="480" height="270" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                        </div>

                        <input type='checkbox' id='2,9'></input>
                        <label htmlFor='2,9'><h4>6. Quais são os dados do Certificado?</h4> <FontAwesomeIcon icon={faPlus} color='#6F8190' fontSize='20px' /></label>
                        <div className='resposta'>
                            <p>
                                Os dados estão <b>pré-definidos na plataforma</b>: NOME do aluno, CPF, DATA de CONCLUSÃO, NOME do curso e CARGA-HORÁRIA. Em absolutamente NENHUMA hipótese podemos alterar este formato, incluir ou excluir campos ou gerar certificados manualmente para atender a necessidades específicas.
                            </p>
                        </div>

                        <input type='checkbox' id='2,10'></input>
                        <label htmlFor='2,10'><h4>7. Posso mudar o certificado para o trabalho?</h4> <FontAwesomeIcon icon={faPlus} color='#6F8190' fontSize='20px' /></label>
                        <div className='resposta'>
                            <p>
                                <b>Não</b>. Os certificados são disponibilizados automaticamente na plataforma. Não controlamos a data de INÍCIO do curso, a FREQUÊNCIA do aluno ou avaliação de DESEMPENHO individual. Nossos cursos são focados na preparação para concursos (não são cursos formais de extensão profissional).
                            </p>
                        </div>


                    </div>

                    <div className='questao'>
                        <h3>Serviço Adquirido</h3>
                        <div className='color-rule'></div>

                        <input type='checkbox' id='3,1'></input>
                        <label htmlFor='3,1'><h4>1. O que está incluso?</h4> <FontAwesomeIcon icon={faPlus} color='#6F8190' fontSize='20px' /></label>
                        <div className='resposta'>
                            <p>
                                Acesso à área de alunos, o Nutror, onde <b>TODAS</b> as aulas em vídeo usadas nos Planos de Estudo são disponibilizadas. 
                                Todas os planos (Anual, Bi-Anual e Tri-Anual) dão acesso a todos os mais de <b>{numeros.numero_planos}</b> planos de estudo por edital, 
                                a todos os mais de <b>{numeros.numero_modulos}</b> módulos e com mais de <b>{numeros.numero_aulas}</b> aulas em vídeo. 
                                Veja todos os <b>{numeros.numero_cursos}</b> cursos listados <a href='/cursos/todos' target='_blank'>aqui</a>.                                 
                            </p>
                        </div>

                        <input type='checkbox' id='3,2'></input>
                        <label htmlFor='3,2'><h4>2. Tenho direito às atualizações?</h4> <FontAwesomeIcon icon={faPlus} color='#6F8190' fontSize='20px' /></label>
                        <div className='resposta'>
                            <p>
                                Sim! As atualizações são feitas periodicamente pelos professores e, sempre que forem adicionadas aulas aos cursos, módulos e planos de estudo, o aluno terá direito enquanto seu plano estiver vigente.
                            </p>
                        </div>

                        <input type='checkbox' id='3,3'></input>
                        <label htmlFor='3,3'><h4>3. Quantas atualizações ocorrerão?</h4> <FontAwesomeIcon icon={faPlus} color='#6F8190' fontSize='20px' /></label>
                        <div className='resposta'>
                            <p>O máximo possível. Porém, é importante ressaltar que o serviço contratado é o acesso ao que está na plataforma no MOMENTO da aquisição. Apenas  isso deve nortear a decisão de compra. Decida considerando SOMENTE o que está na plataforma no momento da sua compra.</p>
                        </div>

                        <input type='checkbox' id='3,4'></input>
                        <label htmlFor='3,4'><h4>4. E se eu assinar e não tiver atualizações?</h4> <FontAwesomeIcon icon={faPlus} color='#6F8190' fontSize='20px' /></label>
                        <div className='resposta'>
                            <p>
                                Por isso, <strong>não considere</strong> a sua expectativa subjetiva sobre o "concurso 'C' que poderá ser aberto no dia 'D' pelo órgão 'O' e pela banca 'B' com o conteúdo 'XPTO'". Nossa decisão de gravar conteúdos novos depende de fatores internos como: priorização, janela de tempo dos professores, experiência sobre o que é relevente (ou não), visão das tendências etc.
                            </p>
                        </div>

                        <input type='checkbox' id='3,5'></input>
                        <label htmlFor='3,5'><h4>5. O conteúdo está todo atualizado?</h4> <FontAwesomeIcon icon={faPlus} color='#6F8190' fontSize='20px' /></label>
                        <div className='resposta'>
                            <p>
                                Essa pergunta não faz tanto sentido em uma base que é uma das maiores e, seguramente, a mais antiga com foco em TI do Brasil. Enfim, há coisas sendo gravadas continuamente há mais de 14 anos em um processo contínuo e cumulativo. A lista dos módulos lançados nos últimos anos, por data de gravação, pode ser vista neste link <a href="/atualizacoes">aqui</a>.
                            </p>
                        </div>

                        <input type='checkbox' id='3,6'></input>
                        <label htmlFor='3,6'><h4>6. O conteúdo é todo focado?</h4> <FontAwesomeIcon icon={faPlus} color='#6F8190' fontSize='20px' /></label>
                        <div className='resposta'>
                            <p>
                                Gravamos conteúdos constentemente para a plataforma e criamos planos de estudo com base no material gravado. Não regravamos o conteúdo a cada novo edital (ninguém faz isso, na verdade), pois isso envolveria dezenas de professores regravando centenas de vezes a mesma coisa. Dizer que o conteúdo é “focado no edital” (quando, na verdade, é a mesma teoria regravada com os mesmo exercícios, mesmo exemplos etc.) é um discurso puramente de marketing que não praticamos.
                            </p>
                        </div>

                        <input type='checkbox' id='3,7'></input>
                        <label htmlFor='3,7'><h4>7. Quando será gravado o conteúdo XPTO?</h4> <FontAwesomeIcon icon={faPlus} color='#6F8190' fontSize='20px' /></label>
                        <div className='resposta'>
                            <p>
                                Não há data. Considere SOMENTE o conteúdo disponível no momento da sua aquisição na plataforma. O conteúdo da área de TI tem se tornado virtualmente "infinito" e nós estamos há mais tempo do que qualquer um perseguindo o objetivo de cobrir o máximo possível. PORÉM, ninguém tem "tudo", por vários motivos. No momento, a plataforma possui mais de {numeros.numero_aulas} aulas e, todas elas, "envelhecendo" a cada dia. O processo de renovação é contínuo e, quanto mais material temos, mais demorado fica substituir ou renovar tudo.
                            </p>
                        </div>

                        <input type='checkbox' id='3,8'></input>
                        <label htmlFor='3,8'><h4>8. Vocês têm disciplinas de outras áreas?</h4> <FontAwesomeIcon icon={faPlus} color='#6F8190' fontSize='20px' /></label>
                        <div className='resposta'>
                            <p>
                                Nosso foco é Tecnologia da Informação. Por causa da sua relação com Ciência de Dados etc., existe na plataforma uma área de disciplinas básicas, tais como Raciocínio Lógico e Estatística <a href="cursos/categoria/1" target="_blank">(veja aqui)</a>. Mas, não é nosso foco. Não gravamos conteúdos específicos para um determinado edital e não temos muito material direcionado para disciplinas fora da área de TI.
                            </p>
                        </div>

                        <input type='checkbox' id='3,9'></input>
                        <label htmlFor='3,9'><h4>9. Vocês têm todas as disciplinas de TI?</h4> <FontAwesomeIcon icon={faPlus} color='#6F8190' fontSize='20px' /></label>
                        <div className='resposta'>
                            <p>
                                Ninguém está há mais tempo com foco específico em TI do que nós. Sabemos que "cobrir tudo" é impensável, uma vez que o órgão pode solicitar uma nova disciplina no momento em que o edital é criado e até conhecimento de aplicativos usados somente pelo órgão. Não gravamos conteúdos específicos para um órgão ou uma banca, pois sabemos que o candidato competitivo aprende o assunto e, assim, responde questões de qualquer banca. Quer poupar tempo? Estude sem ficar acreditando em falsas adivinhações sobre o "perfil de cobrança da banca".
                            </p>
                        </div>

                        <input type='checkbox' id='3,10'></input>
                        <label htmlFor='3,10'><h4>10. O que NÃO está incluso?</h4> <FontAwesomeIcon icon={faPlus} color='#6F8190' fontSize='20px' /></label>
                        <div className='resposta'>
                            <p>
                                Não estão inclusas horas de <b>consultoria</b>, <b>mentoria</b>, <b>coaching</b> ou qualquer outro formato de <b>curso individualizado</b>. Este tipo de serviço envolve uma interação personificada entre professor e aluno, o que, considerando centenas de interessados e, por outro lado, o tempo escasso dos professores, seria inviável oferecer a todos sem aumentar muito o preço cobrado pelo plano.
                            </p>
                        </div>

                        <input type='checkbox' id='3,11'></input>
                        <label htmlFor='3,11'><h4>11. A mentoria no futuro será inclusa?</h4> <FontAwesomeIcon icon={faPlus} color='#6F8190' fontSize='20px' /></label>
                        <div className='resposta'>
                            <p>
                            Não. Dependendo da disponibilidade de um professor, poderá ser oferecido outro tipo de serviço no formato individualizado. PORÉM, as regras serão definidas caso a caso, uma vez que se trata de um novo serviço não listado no Provas de TI atualmente. Qualquer nova oferta de <b>consultoria</b>, <b>mentoria</b>, <b>coaching</b> ou qualquer <b>curso individualizado</b>. obedecerá a novas diretrizes e valores cabendo ao aluno aderir ou não conforme sua conveniência.
                            </p>
                        </div>
                    </div>

                    <div className='questao'>
                        <h3>Como Usar o Material</h3>
                        <div className='color-rule'></div>

                        <input type='checkbox' id='4,1'></input>
                        <label htmlFor='4,1'><h4>1. O que são os planos de estudo?</h4> <FontAwesomeIcon icon={faPlus} color='#6F8190' fontSize='20px' /></label>
                        <div className='resposta'>
                            <p>
                            Os planos de estudo são apenas ferramentas de mapeamento rápido de um edital, disciplina etc. a fim de facilitar a busca pelo conteúdo já existente em nosso site. Eles são criados pelos professores após análise, caso a caso, da relevância de um edital. Veja como funciona no vídeo abaixo.
                            </p>
                            <iframe title='video' src="https://player.vimeo.com/video/362605142" width="480" height="270" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                        </div>

                        <input type='checkbox' id='4,2'></input>
                        <label htmlFor='4,2'><h4>2. Tem Plano de Estudo p/ o Concurso XPTO?</h4> <FontAwesomeIcon icon={faPlus} color='#6F8190' fontSize='20px' /></label>
                        <div className='resposta'>
                            <p>
                            Estamos há mais de <b>{numeros.numero_anos} anos</b> cobrindo os principais Concursos de TI (tribunais, policiais, agências e qualquer outro de amplitude 
                            nacional ou regional). Caso tenha algum edital relevante que queira sugerir, entre em contato. 
                            A análise será feita pelos professores e, se aprovado, o plano normalmente é gerado entre 02 e 05 dias. 
                            Lembrando que nem todos os planos serão obrigatoriamente feitos e os prazos dependem da demanda de outros planos já em elaboração etc.
                            </p>
                        </div>

                        <input type='checkbox' id='4,3'></input>
                        <label htmlFor='4,3'><h4>3. Vocês têm tudo do Concurso XPTO?</h4> <FontAwesomeIcon icon={faPlus} color='#6F8190' fontSize='20px' /></label>
                        <div className='resposta'>
                            <p>Não. Estamos há <b>{numeros.numero_anos} anos</b> nos dedicando a gravar conteúdo das disciplinas de <b>TI</b>. Não é raro um órgão cobrar algo que nunca mais será cobrado em outro concurso (e que não vale o custo-benefício de estudar se você ainda não domina todo o resto). Além disso, para editais mais extensos, possuir 100% do conteúdo só significa aumentar a quantidade de material que o aluno não terá tempo de estudar. Em suma, continuamos a renovar nosso material constantemente focando no que é necessário.
                            </p>

                        </div>

                        <input type='checkbox' id='4,4'></input>
                        <label htmlFor='4,4'><h4>4. Vocês têm todo o conteúdo de TI?</h4> <FontAwesomeIcon icon={faPlus} color='#6F8190' fontSize='20px' /></label>
                        <div className='resposta'>
                            <p>
                                Não. Estamos há <b>{numeros.numero_anos} anos</b> gravando (e atualizando) conteúdos de TI. 
                                No momento, só em vídeos, são mais de {numeros.numero_aulas} aulas (mais de {numeros.numero_horas} horas de aulas focadas).
                                Quando sai um edital, verificamos se há algo que não temos e decidimos se vale ou não à pena gravar (há coisas "inventadas" 
                                por um órgão que nunca serão cobradas em lugar nenhum... às vezes, nem mesmo na própria prova do órgão). 
                                Todos os planos dão direito de acesso a toda a nossa base (veja tudo <a href="/cursos/todos">aqui</a>) e às respetivas atualizações posteriores 
                                (veja atualizações <a href="/atualizacoes">aqui</a>).
                            </p>
                        </div>

                        <input type='checkbox' id='4,5'></input>
                        <label htmlFor='4,5'><h4>5. Posso fazer o download dos vídeos?</h4> <FontAwesomeIcon icon={faPlus} color='#6F8190' fontSize='20px' /></label>
                        <div className='resposta'>
                            <p>
                                No celular Apple ou Android é possível baixar para assistir em <strong>modo off-line</strong> usando o app Nutror. Esta função <strong>não</strong>está disponível no desktop, PC ou computador. Todas as aulas gravadas após <strong>Jan/2019</strong> foram configuradas para este modo. Na prática, elas são as usadas nos Planos de Estudo (com raras exceções). 
                            </p>
                        </div>

                        <input type='checkbox' id='4,6'></input>
                        <label htmlFor='4,6'><h4>6. Posso assistir em modo off-line no celular?</h4> <FontAwesomeIcon icon={faPlus} color='#6F8190' fontSize='20px' /></label>
                        <div className='resposta'>
                            <p>
                                Sim, é possível assistir em modo off-line no app do Nutror. Esta função está presente nas aulas gravadas a partir de 2019 (elas possuem o ródulo "novo" nas páginas de descrição aqui do Provas de TI). Aos poucos, as aulas mais antigas também estão sendo substituídas ou atualizadas para permitir o modo off-line. Em geral, damos preferência às aulas mais recentes quando criamos os Planos de Estudo (embora possa haver alguma exceção por conta da relevância de uma aula, mesmo um pouco mais antiga).
                            </p>
                        </div>

                        <input type='checkbox' id='4,7'></input>
                        <label htmlFor='4,7'><h4>7. Posso baixar os slides da aula?</h4> <FontAwesomeIcon icon={faPlus} color='#6F8190' fontSize='20px' /></label>
                        <div className='resposta'>
                            <p>
                                Sim. Os slides devem  ser baixados aqui mesmo no sítio do Provas de TI.  Mas sempre para uso pessoal. Os slides em PDF podem ser marcados com inscrições de segurança, visíveis ou não visíveis (marca d´água), quando é feito o download.
                            </p>
                        </div>

                        <input type='checkbox' id='4,8'></input>
                        <label htmlFor='4,8'><h4>8. Posso interagir com os professores?</h4> <FontAwesomeIcon icon={faPlus} color='#6F8190' fontSize='20px' /></label>
                        <div className='resposta'>
                            <p>
                                Não está previsto dentro da plataforma (independe do Provas de TI). O serviço oferecido é o de acesso online às aulas gravadas. Alguns materiais possuem os contatos do professor e você pode utilizá-los, caso deseje. Neste caso, cabe ao professor responder (ou não), conforme sua conveniência, disponibilidade de tempo etc.
                            </p>
                        </div>

                        <input type='checkbox' id='4,9'></input>
                        <label htmlFor='4,9'><h4>9. O que estou adquirindo então?</h4> <FontAwesomeIcon icon={faPlus} color='#6F8190' fontSize='20px' /></label>
                        <div className='resposta'>
                            <p>
                                Acesso às aulas gravadas. Você assiste, estuda e interage com outros alunos. Como as aulas foram gravadas no passado,os professores não estão necessariamente disponíveis hoje para responder às dúvidas. Algumas dúvidas poderão ser respondidas pela equipe atual de professores e sócios do Provas de TI, quando for possível. Porém, isso é um "bônus" e não faz parte do serviço contratado.
                            </p>
                        </div>

                        <input type='checkbox' id='4,10'></input>
                        <label htmlFor='4,10'><h4>10. O material é específico para o concurso XPTO?</h4> <FontAwesomeIcon icon={faPlus} color='#6F8190' fontSize='20px' /></label>
                        <div className='resposta'>
                            <p>
                                Não existe "teoria específica" para o concurso XPTO. 
                                Após mais de <b>{numeros.numero_anos} anos</b>na área, sabemos que quem passa é quem estuda a teoria da forma correta. Por isso, procuramos ter melhores 
                                materiais que são gravados constantemente pelos melhores professores disponíveis. Nosso material serve para qualquer concurso que 
                                cobre conteúdos da área de TI. Claro que não faltam discursos de marketing dizendo que existe "uma teoria específica"  para a banca "XPTO".
                                Mas basta analisar o conteúdo que é entregue para ver que, obviamente, trata-se do mesmo material reagrupado várias vezes para cobrir diversos editais.
                                A diferença é que nós deixamos isso bem claro para você e que nós começamos a gravar materiais para TI antes de TODOS que estão por aí hoje.
                            </p>
                        </div>

                        <input type='checkbox' id='4,11'></input>
                        <label htmlFor='4,11'><h4>11. Os Planos de Estudo são direcionamentos?</h4> <FontAwesomeIcon icon={faPlus} color='#6F8190' fontSize='20px' /></label>
                        <div className='resposta'>
                            <p>
                                De certa forma sim. Os Planos de Estudo são uma indicação dos professores com relação a quais materias existentes na plataforma o aluno pode estudar para um determinado concurso. A diferença é que não gravamos o material pensando em um determinado concurso (não faz o menor sentido). Gravamos pensando em ensinar da melhor forma, com os melhores exemplos, independente do concurso que o aluno irá fazer. Os planos de estudo apenas aceleram a busca pelo material em nosso site, indicando quais das nossas milhares de aulas são mais relevantes para um  concurso e quais não são. Desta forma, você pode estudar para mais de um Concurso, caso queira, sem pagar nada a mais.
                            </p>
                        </div>

                    </div>

                    <div className='questao'>
                        <h3>Turmas Fechadas e Aulões</h3>
                        <div className='color-rule'></div>

                        <input type='checkbox' id='5,1'></input>
                        <label htmlFor='5,1'><h4>1. Turmas fechadas estão inclusas no Plano?</h4> <FontAwesomeIcon icon={faPlus} color='#6F8190' fontSize='20px' /></label>
                        <div className='resposta'>
                            <p>
                                Não. Turmas fechadas envolvem disponibilidade de tempo do professor para atendimento individualizado (aulas, mentorias etc.). Estas turmas são formadas com data de início e fim e agendamentos individuais. Há cobrança à parte e, em princípio, são iniciativas diferentes não inclusas nos planos (Anual, Bi-Anual e Tri-Anual).
                            </p>
                        </div>

                        <input type='checkbox' id='5,2'></input>
                        <label htmlFor='5,2'><h4>2. Os "aulões" ao vivo estão inclusos no Plano?</h4> <FontAwesomeIcon icon={faPlus} color='#6F8190' fontSize='20px' /></label>
                        <div className='resposta'>
                            <p>
                                Sim. Os eventos online são abertos ao público em geral e possuem limitações do stream utilizado (Zoom etc.). A liberação para os assinantes dos planos será analisada caso a caso (considerando as limitações do stream), mas geralmente será possível assistir ao vivo. De qualquer forma, o material gravado sempre será disponibilizado posteriormente dentro da área do assinante (Nutror).
                            </p>
                        </div>

                        <input type='checkbox' id='5,3'></input>
                        <label htmlFor='5,3'><h4>3. Assinante tem acesso ao conteúdo gravado?</h4> <FontAwesomeIcon icon={faPlus} color='#6F8190' fontSize='20px' /></label>
                        <div className='resposta'>
                            <p>
                                Conteúdos referentes às turmas fechadas não serão disponibilizados dentro dos planos (Anual, Bi-Anual e Tri-Anual). Poderá haver exceções de conteúdos disponibilizados a critério do Provas de TI.
                            </p>
                        </div>
                    </div>

                    <div className='questao'>
                        <h3>Discursivas dos Planos</h3>
                        <div className='color-rule'></div>

                        <input type='checkbox' id='6,1'></input>
                        <label htmlFor='6,1'><h4>1. Como entro em contato com o Professor?</h4> <FontAwesomeIcon icon={faPlus} color='#6F8190' fontSize='20px' /></label>
                        <div className='resposta'>
                            <p>
                                O agendamento das entregas é feito <strong>por e-mail</strong> diretamente com o professor. Os dados de contato são informados dentro do curso de discursivas (TRM2101) em que o aluno é 
                                matriculado após a aquisição do plano. Ao longo das aulas o professor explica como escolher temas, enviar as discursivas etc.                                
                            </p>
                        </div>

                        <input type='checkbox' id='6,2'></input>
                        <label htmlFor='6,2'><h4>2. Posso direcionar para a minha necessidade?</h4> <FontAwesomeIcon icon={faPlus} color='#6F8190' fontSize='20px' /></label>
                        <div className='resposta'>
                            <p>
                                Sim. A correção é feita com foco no texto de discursivas (sem depender do tema). O foco é nas técnicas de redação.
                                 O aluno pode escolher o tema direcionando para a sua necessidade.
                                 As discursivas devem ser enviadas no formato padrão de <strong>30 a 40 linhas</strong>.
                            </p>
                        </div>

                        <input type='checkbox' id='6,3'></input>
                        <label htmlFor='6,3'><h4>3. Posso enviar qualquer tamanho de texto?</h4> <FontAwesomeIcon icon={faPlus} color='#6F8190' fontSize='20px' /></label>
                        <div className='resposta'>
                            <p>
                                Não. Os textos devem ter entre <strong>30 e 40 linhas</strong>, que é o tamanho padrão para a maioria das bancas e concursos. Para formatos e tamanhos excepcionais, o texto constará como duas discursivas, pelo menos (ou mais, dependendo do tamanho que ultrapassar).
                            </p>
                        </div>

                        <input type='checkbox' id='6,4'></input>
                        <label htmlFor='6,4'><h4>4. Quanto tempo terei para usar?</h4> <FontAwesomeIcon icon={faPlus} color='#6F8190' fontSize='20px' /></label>
                        <div className='resposta'>
                            <p>
                                Você deve combinar os temas e enviar as discursivas que tem direito dentro do período de <strong>vigência do plano</strong>. A quantidade total de <b>0{numeros.numero_discursivas} discursivas </b> deve ser enviada ao longo deste período de vigência.
                            </p>
                        </div>

                        <input type='checkbox' id='6,5'></input>
                        <label htmlFor='6,5'><h4>5. Como é o Curso de Discursivas?</h4> <FontAwesomeIcon icon={faPlus} color='#6F8190' fontSize='20px' /></label>
                        <div className='resposta'>
                            <p>
                                Trata-se de um curso gravado com duração de 09 (<strong>NOVE</strong>) horas realizado pelo Prof. Walter Cunha. As gravações deste curso são disponibilizadas para servir de embasamento teórico para que o aluno possa desenvolver os seus textos.
                            </p>
                        </div>
                    </div>
                </Questoes>
            </FaqStyle>

            <Rodape />

            <IrAoTopoWidget />
        </>
    )
}

export default Faq;