import { LandingMentoriaStyle } from "./styles/LandingMentoria.style";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { API_URL } from '../config';
import { faUpRightFromSquare, faBoxOpen, faChalkboardUser, faEquals, faPlus, faUserGroup, faVideo, faEnvelope, faArrowUp, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { faFacebookF, faInstagram, faTelegram, faTwitter, faWhatsapp, faYoutube } from "@fortawesome/free-brands-svg-icons";
import numeros from "../dados/numeros.json";
import { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import CarregamentoWidget from '../componentes/carregamentoWidget';
import { startCountdown } from '../functions/countdownTimer';


const LandingMentoria = () => {
    useEffect(() => {
        const currentDateT = new Date(); // Data para o primeiro contador
        currentDateT.setHours(24, 0, 0, 0);

        startCountdown({
            diaId: 'dian',
            hrsId: 'hrsn',
            minId: 'minn',
            segId: 'segn',
            countDownDate: currentDateT.getTime() // Passe a data de término
        });
    }, []);

    useEffect(() => {
        const currentDateT = new Date(); // Data para o primeiro contador
        currentDateT.setHours(24, 0, 0, 0);

        startCountdown({
            diaId: 'diav',
            hrsId: 'hrsv',
            minId: 'minv',
            segId: 'segv',
            countDownDate: currentDateT.getTime() // Passe a data de término
        });
    }, []);

    const { mentoriaId } = useParams();

    interface MentoriaInfo {

        titulo: string,
        resumo: string,
        professor: string,
        minicv: string,
        banner: string,
        banner_g: string,
        banner_m: string,
        alt: string,
        diadasemana: string,
        stringhorario: string,

        encontros: number,
        lives: number,
        mentorias: number,
        discursivas: number,
        telegram: number,
        planoanual: number,

        link: string,
        linkavista: string,
        linkvip: string,
        linkvipavista: string,
        parcela: number,
        parcelavip: number,

        bonusvip: number
    }

    const [mentoriaInfo, setMentoriaInfo] = useState<MentoriaInfo>();

    useEffect(() => {
        const getMentoriaPorId = async () => {
            axios.get(`${API_URL}/api/mentorias/get/mentoriaPorId`, {
                params: {
                    id: mentoriaId
                }
            })
                .then(response => setMentoriaInfo(response.data.results[0]));
        }
        getMentoriaPorId();
    }, [mentoriaId]);

    interface MentoriaDatas {
        id: number;
        data: Date;
        imagem: string;
        link: string;
    }

    const [mentoriaDatasPorId, setMentoriaDatasPorId] = useState<MentoriaDatas[]>();

    useEffect(() => {
        const getMentoriaDatasPorId = async () => {
            axios.get(`${API_URL}/api/mentorias/get/mentoriaDatasPorId`, {
                params: {
                    id: mentoriaId
                }
            })
                .then(response => setMentoriaDatasPorId(response.data.results));
        }
        getMentoriaDatasPorId();
    }, [mentoriaId]);


    interface MentoriaConteudos {
        id: number,
        titulo: string
    }

    const [mentoriaConteudosPorId, setMentoriaConteudosPorId] = useState<MentoriaConteudos[]>();

    useEffect(() => {
        const getMentoriaConteudosPorId = async () => {
            axios.get(`${API_URL}/api/mentorias/get/mentoriaConteudosPorId`, {
                params: {
                    id: mentoriaId
                }
            })
                .then(response => setMentoriaConteudosPorId(response.data.results));
        }
        getMentoriaConteudosPorId();
    }, [mentoriaId]);


    useEffect(() => {
        // Função para inicializar a visibilidade do cabeçalho
        const initializeHeaderVisibility = () => {
            const header = document.getElementById('header');
            if (header) {
                header.style.top = '-100px'; // Esconde o cabeçalho acima da janela de visualização

                if (window.scrollY > 100) {
                    header.classList.remove('header-hidden');
                    header.style.backgroundColor = '#000000';
                } else {
                    header.classList.add('header-hidden');
                    header.style.backgroundColor = 'transparent';
                }
            }
        };

        // Chama a função para configurar a visibilidade inicial do cabeçalho
        initializeHeaderVisibility();

        // Adiciona o event listener de scroll
        window.addEventListener('scroll', headerScroll);

        // Função de limpeza que remove o event listener de scroll
        return () => window.removeEventListener('scroll', headerScroll);
    }, []); // O array vazio [] como segundo argumento indica que este useEffect deve ser executado apenas uma vez



    const headerScroll = () => {
        const header = document.getElementById('header');
      
        // Verifica se o elemento 'header' existe antes de tentar manipulá-lo
        if (header) {
            if (window.scrollY > 100) {
                header.classList.remove('header-hidden');
                header.style.backgroundColor = '#000000';
            } else {
                header.classList.add('header-hidden');
                header.style.backgroundColor = 'transparent';
            }
        }
    }   
      

    const topScroll = () => {

        let backTop = document.getElementById('backTop');

        if ((document.body.scrollTop > 1000 || document.documentElement.scrollTop > 1000) && backTop) {
            backTop.style.display = 'block';
        }
        else if (backTop) {
            backTop.style.display = 'none';
        }
    }

    window.onscroll = () => {
        headerScroll();
        topScroll();
    }


    let valorFull = 0;              //Valor final no pacote de novo aluno.
    let valorVIP = 0;               //Valor final no pacote de assinante.
    const anoAtual = new Date().getFullYear();


    return (
        <>
            {!mentoriaInfo ? <CarregamentoWidget /> :
                <LandingMentoriaStyle>
                    <div className="mentoria" id={mentoriaInfo.banner} >
                        <div className="header header-hidden" id="header">
                            <a href="#mentoria-coletiva">MENTORIA COLETIVA</a>
                            <a href="#mentoria-individual">MENTORIA INDIVIDUAL</a>

                            <a href="#discursivas">
                                {mentoriaInfo.discursivas === 0 ? "" : `DISCURSIVAS`}
                            </a>

                            <a href="#grupo-telegram">GRUPO TELEGRAM</a>
                            <a href="#planos-de-estudo">PLANOS DE ESTUDO</a>
                            <a href="#investimento">INVESTIMENTO</a>
                            <a href="#faq">FAQ</a>
                        </div>
                        {
                        mentoriaInfo && mentoriaInfo.banner &&
                        <div className="banner" id={mentoriaInfo.banner}></div>
                        }
                        {
                            mentoriaInfo && mentoriaInfo.banner_m &&
                            <div className="banner_m" 
                                style={{ backgroundImage: `url(/assets/images/promos/${mentoriaInfo.banner_m})` }} 
                                id={mentoriaInfo.banner_m}>
                            </div>
                        }

                        <div className="sobre">
                            <div className="info left">
                                <h4>Sobre a <span className="txt-color">Mentoria</span></h4>

                                <div className="rule"></div>

                                <p className="detalhes-txt">
                                    Este curso vai lhe trazer resultados mais rápidos!
                                    Vamos acelerar seu processo de aprendizado em <strong>{mentoriaInfo.titulo}</strong>.
                                </p>

                                <a href="#mentoria-coletiva">
                                    <button className="button btn-1">VER DETALHES</button>
                                </a>
                            </div>

                            <div className="info">
                                <div>
                                    <a href="#mentoria-coletiva">
                                        <FontAwesomeIcon className="icon" icon={faVideo} />
                                    </a>
                                </div>

                                <p className="title">Aulas Via Zoom</p>

                                <p>Acesso a encontros onlines para tirar dúvidas e manter-se informado.</p>
                            </div>


                            {mentoriaInfo.discursivas === 0 ?
                                ""
                                :
                                <div className="info">
                                    <div>
                                        <a href="#discursivas">
                                            <FontAwesomeIcon className="icon" icon={faUserGroup} />
                                        </a>
                                    </div>

                                    <p className="title">Correção Individual</p>

                                    <p>Correção e devolução das redações com apontamentos do professor.</p>
                                </div>
                            }

                            <div className="info">
                                <div>
                                    <a href="#mentoria-individual">
                                        <FontAwesomeIcon className="icon" icon={faChalkboardUser} />
                                    </a>
                                </div>

                                <p className="title">Mentoria Individual</p>

                                <p>Você poderá agendar mentoria individualizada com o Professor.</p>
                            </div>
                        </div>

                        <div className="aulas" id="mentoria-coletiva">
                            <div className="info">
                                <h4>Encontros on-line via <span className='txt-color'>Zoom</span></h4>

                                <div className="rule"></div>

                                <p className="title">Haverá {mentoriaInfo.encontros.toString().padStart(2, '0')} encontros fechados de mentoria em grupo via Zoom.</p>

                                <p className="detalhes-txt">
                                    Você poderá interagir com o professor e com outros colegas.
                                    Serão <b>{mentoriaInfo.encontros.toString().padStart(2, '0')}</b> encontros fechados no <b>Zoom</b> e {mentoriaInfo.lives.toString().padStart(2, '0')} lives abertas no Youtube,
                                    sempre às <b>{mentoriaInfo.diadasemana}</b> e sempre das <b>{mentoriaInfo.stringhorario}</b>.
                                    Você poderá assistir ao vivo e, caso não possa, o conteúdo ficará gravado para você assistir
                                    posteriormente na plataforma (área exclusiva de alunos com acesso restrito).
                                    As datas previstas são:

                                    {!mentoriaDatasPorId ? <CarregamentoWidget /> :
                                        <ul>
                                            {mentoriaDatasPorId.map((datas) => {
                                                return (
                                                    <li>
                                                        <b>{new Date(datas.data).toLocaleDateString()}<br /></b>
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    }

                                </p>

                                <p className="detalhes-txt">
                                    Se a mentoria está em andamento e alguma data acima já passou, não tem problema! Você ainda pode se matricular e assistir às aulas gravadas através da área de estudos (o Nutror).
                                    Basta clicar nos links abaixo e se logar.                                     
                                </p>

                                {!mentoriaDatasPorId ? <CarregamentoWidget /> :
                                    <div className="cards">
                                        {mentoriaDatasPorId.map((datas) => {
                                            const data = new Date(datas.data);
                                            data.setHours(23, 59, 59);

                                            //let now = new Date();

                                            let imgName: string;
                                            let linkURL: string;

                                            //if (data < now) {
                                                imgName = datas.imagem;
                                            //} else {
                                            //    imgName = 'zoom-em-breve.jpg';
                                            //}
                                            linkURL = datas.link;

                                            return (
                                                <a href={`${linkURL}`} target="_blank" rel="noopener noreferrer" key={datas.id} className="aula-wrap">
                                                    <img className="img-aula" src={`../assets/images/mentorias/${imgName}`} alt="zoom-video"></img>
                                                    <p className="data-aula">
                                                        {data.toLocaleDateString()}
                                                        <FontAwesomeIcon className="icon-aula" icon={faUpRightFromSquare} />
                                                    </p>
                                                </a>
                                            )
                                        })}
                                    </div>
                                }

                                <FontAwesomeIcon className="icon-aulas" icon={faBoxOpen} />
                                <p className="title-aulas">{mentoriaInfo.encontros}x mentorias coletivas</p>
                            </div>

                            <img src="../assets/images/mentorias/reunioes-via-zoom-mockup.png" alt="aulas"></img>
                        </div>

                        <div className="conteudo-mentoria">
                            <div className="conteudo-card">
                                <h4>Conteúdo Programático da Mentoria</h4>
                                {!mentoriaConteudosPorId ? <CarregamentoWidget /> :
                                    <ul>
                                        {mentoriaConteudosPorId.map((conteudos) => {
                                            return (
                                                <div key={conteudos.id}>
                                                    <li>
                                                        {conteudos.titulo}
                                                    </li>
                                                </div>
                                            )
                                        })}
                                    </ul>
                                }
                            </div>
                        </div>

                        <div className="aulas white" id="mentoria-individual">
                            <img src="../assets/images/mentorias/mentoria-individual-mockup.png" alt="aulas"></img>

                            <div className="info">
                                <h4>Mentoria <span className='txt-color'>Individualizada</span></h4>

                                <div className="rule"></div>

                                <p className="title">Você terá direito a {mentoriaInfo.mentorias.toString().padStart(2, '0')} hora agendada com o professor.</p>

                                <p className="detalhes-txt">
                                    As pessoas são diferentes e, por isso, há diversas formas de aprender.
                                    Pensando nisso, este programa de estudo e mentoria possui espaço
                                    para <b>{mentoriaInfo.mentorias.toString().padStart(2, '0')}</b> hora de acompanhamento individual agendado diretamente com o professor.
                                    Este tempo, somado aos outros itens que fazem parte do programa, trará benefícios
                                    individualizados exclusivos que servirão de diferencial para o aluno.
                                </p>

                                <FontAwesomeIcon className="icon-aulas" icon={faBoxOpen} />
                                <p className="title-aulas">{mentoriaInfo.mentorias}x mentoria individualizada</p>
                            </div>
                        </div>

                        {mentoriaInfo.discursivas === 0 ?
                            ""
                            :

                            <div className="aulas" id="discursivas">
                                <div className="info">
                                    <h4>Correção individual de <span className='txt-color'>Discursivas</span></h4>

                                    <div className="rule"></div>

                                    <p className="title">Você terá direito a correção de {mentoriaInfo.discursivas.toString().padStart(2, '0')} redações.</p>

                                    <p className="detalhes-txt">
                                        A prática leva à perfeição. Você terá direito a
                                        elaborar <b>{mentoriaInfo.discursivas.toString().padStart(2, '0')}</b> redações
                                        sobre orientação do professor envolvendo temas de interesse.
                                        As redações serão corrigidas pelo professor com os devidos apontamentos.
                                        Isso lhe proporcionará não somente a correção de rumo, mas também a
                                        possibilidade de você continuar caminhando sozinho após o programa.
                                    </p>

                                    <FontAwesomeIcon className="icon-aulas" icon={faBoxOpen} />
                                    <p className="title-aulas">{mentoriaInfo.discursivas}x correções de redação</p>
                                </div>
                                <img src="../assets/images/mentorias/correcao-discursiva-mockup.png" alt="aulas"></img>
                            </div>
                        }


                        <div className="aulas white" id="grupo-telegram">
                            <img src="../assets/images/mentorias/grupo-telegram-mockup.png" alt="aulas"></img>

                            <div className="info">
                                <h4>Você participará de um <span className='txt-color'>Grupo Exclusivo</span></h4>

                                <div className="rule"></div>

                                <p className="title">Será criado um grupo Telegram para comunicação.</p>

                                <p className="detalhes-txt">
                                    Um dos maiores problemas de quem está na jornada em busca
                                    de uma boa vaga em um concurso é o aspecto motivacional.
                                    Para tanto este programa oferece um grupo exclusivo no qual
                                    você poderá tocar ideias, motivar e ser motivado. Este tipo de
                                    relacionamento é o diferencial de muitos dos alunos bem sucedidos
                                    em concursos. O grupo terá duração de <b>{mentoriaInfo.telegram.toString().padStart(2, '0')} MESES</b>, pelo menos.
                                </p>

                                <FontAwesomeIcon className="icon-aulas" icon={faBoxOpen} />
                                <p className="title-aulas">{mentoriaInfo.telegram}x meses telegram</p>
                            </div>
                        </div>

                        <div className="aulas" id="planos-de-estudo">
                            <div className="info">
                                <h4>{mentoriaInfo.planoanual * 12} MESES de Plano Anual no <span className='txt-color'>Provas de TI</span></h4>

                                <div className="rule"></div>

                                <p className="title">Alunos <b>NOVOS*</b> terão direito a <b>{mentoriaInfo.planoanual === 1 ? "DOZE" : "VINTE E QUATRO"} MESES</b> no Plano Anual.<br /></p>
                                <span>*Consulte o FAQ no final da página.</span>

                                <p className="detalhes-txt">
                                    O Provas de TI é o mais antigo site de preparação com foco
                                    específico em Concursos de TI. Atualmente conta com mais
                                    de <b>{numeros.numero_modulos}</b> módulos totalizando <b>{numeros.numero_aulas}</b> aulas.
                                    Os materiais do Provas de TI já foram utilizados por quase <b>{numeros.numero_alunos}</b> alunos
                                    ao longo de <b>{numeros.numero_anos}</b> anos. Este pacote de mentoria dá acesso a todo este conteúdo
                                    disponível no Plano Anual do <strong>Provas de TI</strong>.
                                    Aproveite!
                                </p>

                                <FontAwesomeIcon className="icon-aulas" icon={faBoxOpen} />
                                <p className="title-aulas">{mentoriaInfo.planoanual}x plano anual</p>
                            </div>

                            <img src="../assets/images/mentorias/provas-de-ti-mockup.png" alt="aulas"></img>
                        </div>

                        <div className="aulas white" id="investimento">
                            <div className="sombra">
                                <h4>Pacote <span className='txt-color'>Alunos Novos (sem Plano)</span></h4>
                                <div className="rule"></div>
                                <div className="alerta">Se você é <b>ALUNO NOVO</b> ou SEM <b>PLANO ATIVO</b>.
                                </div>

                                <div className="row">
                                    <p>{mentoriaInfo.encontros.toString().padStart(2, '0')} Encontros de 3h via Zoom</p>
                                    <p className="cut">R$ {mentoriaInfo.encontros * parseInt(numeros.preco_mentoria_coletiva)},00</p>
                                </div>

                                <div className="row">
                                    <p>{mentoriaInfo.mentorias.toString().padStart(2, '0')} Mentoria de 1h via Zoom</p>
                                    <p className="cut">R$ {mentoriaInfo.mentorias * parseInt(numeros.preco_mentoria_individual)},00</p>
                                </div>

                                <div className="row">
                                    <p>{mentoriaInfo.discursivas.toString().padStart(2, '0')} Redações Corrigidas</p>
                                    <p className="cut">R$ {mentoriaInfo.discursivas * parseInt(numeros.preco_discursiva)},00</p>
                                </div>

                                {/*                                 <div className="row">
                                    <p>Mapas Mentais Temas</p>
                                    <p className="cut">grátis</p>
                                </div> */}

                                <div className="row">
                                    <p>Grupo de Telegram</p>
                                    <p className="cut">grátis</p>
                                </div>

                                <div className="row">
                                    <p>Slides do Professor</p>
                                    <p className="cut">grátis</p>
                                </div>

                                <div className="row">
                                    <span className="txt-color"><FontAwesomeIcon icon={faPlus} /></span>
                                </div>

                                <div className="row">
                                    <p>{mentoriaInfo.planoanual * 12} MESES de Provas de TI</p>
                                    <p className="cut">R$ {mentoriaInfo.planoanual * parseInt(numeros.preco_planoanual)},00</p>
                                </div>

                                <div className="row">
                                    <span className="txt-color"><FontAwesomeIcon icon={faEquals} /></span>
                                </div>

                                <div className="row">
                                    <p>Total dos Itens</p>
                                    <p className="cut">R$ {valorFull +=
                                        mentoriaInfo.encontros * parseInt(numeros.preco_mentoria_coletiva) +
                                        mentoriaInfo.mentorias * parseInt(numeros.preco_mentoria_individual) +
                                        mentoriaInfo.discursivas * parseInt(numeros.preco_discursiva) +
                                        mentoriaInfo.planoanual * parseInt(numeros.preco_planoanual)
                                    },00</p>
                                </div>
                            </div>

                            <div className="sombra">
                                <a href={`${mentoriaInfo.link}`} target='_blank' rel='noreferrer'>
                                    <img src="../assets/images/mentorias/promo-box.png" alt='curso-box'></img>
                                </a>

                                <div className="precos">
                                    <p className="title cut">De R$ {valorFull},00</p>

                                    <p className="title">Por 12x de</p>
                                </div>

                                <div className="precos">
                                    <p className="title">R$</p>
                                    <h4>{mentoriaInfo.parcela.toFixed(2).toString().replace(".", ",")}</h4>
                                </div>

                                <div className="precos">
                                    <a href={`${mentoriaInfo.link}`} target='_blank' rel='noreferrer'>
                                        <button className="button btn-1">PACOTE COMPLETO - CARTÃO</button>
                                    </a>
                                </div>

                                <div className="precos">
                                    <a href={`${mentoriaInfo.linkavista}`} target='_blank' rel='noreferrer'>
                                        <button className="button btn-1">PACOTE COMPLETO - À VISTA</button>
                                    </a>
                                </div>
                                
                                <div className='contador'>
                                    <div className='circle' id='dian'></div>
                                    <div className='circle' id='hrsn'></div>
                                    <div className='circle' id='minn'></div>
                                    <div className='circle' id='segn'></div>
                                </div>


                            </div>
                        </div>


                        <div className="planoativo">
                            <p className="title">Tem um plano ATIVO no Provas de TI?</p>
                            <p className="subtitle">Use o botão de <b>desconto</b> abaixo. Você assinará {mentoriaInfo.bonusvip === 0 ? "SOMENTE" : ""} a mentoria <b>{mentoriaInfo.titulo}.</b></p>
                            <p className="atencao">PORÉM, {mentoriaInfo.bonusvip === 0 ? "NÃO HAVERÁ" : "o"} acréscimo no seu Plano vigente
                                <b>{mentoriaInfo.bonusvip === 0 ? "" : ` será de ${mentoriaInfo.planoanual * 12 * 0.5} MESES`}</b>.</p>
                        </div>
                        <div className="planoativo seta">
                            <img src="../assets/images/mentorias/seta-down.png" alt="Pacote Aluno Ativo"></img>
                        </div>


                        <div className="aulas white" id="investimento">
                            <div className="sombra">
                                <h4>Pacote <span className='txt-color'>Aluno Ativo no Provas de TI</span></h4>
                                <div className="rule"></div>
                                <div className="alerta"><b>SOMENTE</b> para <b>ALUNO ATIVO</b> no Provas de TI.
                                </div>
                                <div className="row">
                                    <p>{mentoriaInfo.encontros.toString().padStart(2, '0')} Encontros de 3h via Zoom</p>
                                    <p className="cut">R$ {mentoriaInfo.encontros * parseInt(numeros.preco_mentoria_coletiva)},00</p>
                                </div>

                                <div className="row">
                                    <p>{mentoriaInfo.mentorias.toString().padStart(2, '0')} Mentoria de 1h via Zoom</p>
                                    <p className="cut">R$ {mentoriaInfo.mentorias * parseInt(numeros.preco_mentoria_individual)},00</p>
                                </div>

                                <div className="row">
                                    <p>{mentoriaInfo.discursivas.toString().padStart(2, '0')} Redação Corrigida</p>
                                    <p className="cut">R$ {mentoriaInfo.discursivas * parseInt(numeros.preco_discursiva)},00</p>
                                </div>

                                {/*                                 <div className="row">
                                    <p>Mapas Mentais Temas</p>
                                    <p className="cut">grátis</p>
                                </div> */}

                                <div className="row">
                                    <p>Grupo de Telegram</p>
                                    <p className="cut">grátis</p>
                                </div>

                                <div className="row">
                                    <p>Slides do Professor</p>
                                    <p className="cut">grátis</p>
                                </div>

                                <div className="row">
                                    <span className="txt-color"><FontAwesomeIcon icon={faPlus} /></span>
                                </div>

                                <div className="row">
                                    <p>{mentoriaInfo.bonusvip === 0 ? "ZERO MESES" : `${mentoriaInfo.planoanual * 12 * 0.5} MESES`} de Provas de TI</p>
                                    <p className="cut">R$ {mentoriaInfo.bonusvip === 0 ? 0 : mentoriaInfo.planoanual * 0.5 * parseInt(numeros.preco_planoanual)},00</p>
                                </div>

                                <div className="row">
                                    <span className="txt-color"><FontAwesomeIcon icon={faEquals} /></span>
                                </div>

                                <div className="row">
                                    <p>Total dos Itens</p>
                                    <p className="cut">R$ {valorVIP +=
                                        mentoriaInfo.encontros * parseInt(numeros.preco_mentoria_coletiva) +
                                        mentoriaInfo.mentorias * parseInt(numeros.preco_mentoria_individual) +
                                        mentoriaInfo.discursivas * parseInt(numeros.preco_discursiva) +
                                        (mentoriaInfo.bonusvip === 0 ? 0 : mentoriaInfo.planoanual * 0.5 * parseInt(numeros.preco_planoanual))
                                    },00</p>
                                </div>
                            </div>

                            <div className="sombra">
                                <a href={`${mentoriaInfo.linkvip}`} target='_blank' rel='noreferrer'>
                                    <img src="../assets/images/mentorias/promo-box.png" alt='curso-box'></img>
                                </a>

                                <div className="precos">
                                    <p className="title cut">De R$ {valorVIP},00</p>

                                    <p className="title">Por 12x de</p>
                                </div>

                                <div className="precos">
                                    <p className="title">R$</p>
                                    <h4>{(mentoriaInfo.parcelavip).toFixed(2).toString().replace(".", ",")}</h4>
                                </div>

                                <div className="precos">
                                    <a href={`${mentoriaInfo.linkvip}`} target='_blank' rel='noreferrer'>
                                        <button className="button btn-1">JÁ TENHO PLANO ATIVO - CARTÃO</button>
                                    </a>
                                </div>

                                <div className="precos">
                                    <a href={`${mentoriaInfo.linkvipavista}`} target='_blank' rel='noreferrer'>
                                        <button className="button btn-1">JÁ TENHO PLANO ATIVO  - À VISTA</button>
                                    </a>
                                </div>

                                <div className='contador'>
                                    <div className='circle' id='diav'></div>
                                    <div className='circle' id='hrsv'></div>
                                    <div className='circle' id='minv'></div>
                                    <div className='circle' id='segv'></div>
                                </div>

                            </div>
                        </div>


                        <div className="aulas prof">
                            <img src="../assets/images/mentorias/wc-sorrindo-p-dir.png" alt="aulas"></img>

                            <div className="info">
                                <img src="../assets/images/mentorias/profwaltercunha.png" alt='prof'></img>

                                <h4>Prof. {mentoriaInfo.professor}</h4>

                                <p className="detalhes-txt">
                                    {mentoriaInfo.minicv}
                                </p>
                            </div>
                        </div>

                        <div className="faq" id="faq">
                            <h4>Alguma dúvida? <span className='txt-color'>Leia nosso FAQ</span></h4>

                            <div className="rule"></div>

                            <p>A maioria das dúvidas é respondida rapidamente no FAQ.</p>
                            <p>Se precisar, envie mensagem no whatsapp.</p>

                            <div className="faq-flex">
                                <div className='questao'>

                                    <input type='checkbox' id='1'></input>
                                    <label htmlFor='1'><h4>Qual é o total de mentorias coletivas?</h4> <FontAwesomeIcon icon={faPlus} color='#6F8190' fontSize='20px' /></label>
                                    <div className='resposta'>
                                        <p>
                                            A mentoria coletiva tem um total de <b>{(mentoriaInfo.encontros * 1.5).toString().padStart(2, '0')} HORAS</b> divididas
                                            em <b>{(mentoriaInfo.encontros).toString().padStart(2, '0')}</b> encontros via Zoom de 1 hora e 30 minutos cada, sempre das <b>{mentoriaInfo.stringhorario}</b>, nos dias: <br />
                                            {!mentoriaDatasPorId ? <CarregamentoWidget /> :
                                                <ul>
                                                    {mentoriaDatasPorId.map((datas) => {
                                                        return (
                                                            <li>
                                                                <b>{new Date(datas.data).toLocaleDateString()}<br /></b>
                                                            </li>
                                                        )
                                                    })}
                                                </ul>
                                            }
                                            O conteúdo será ministrado
                                            ao vivo nestas datas. Você também poderá assistir novamente, quantas vezes
                                            quiser, às gravações que ficarão na área do aluno enquanto seu plano no Provas
                                            de TI estiver vigente.
                                        </p>
                                    </div>

                                    {/*                                     <input type='checkbox' id='2'></input>
                                    <label htmlFor='2'><h4>Quando serão as mentorias coletivas?</h4> <FontAwesomeIcon icon={faPlus} color='#6F8190' fontSize='20px' /></label>
                                    <div className='resposta'>
                                        <p>
                                            São <b>{mentoriaInfo.encontros.toString().padStart(2, '0')} ENCONTROS</b>  on-line, com 1 hora e 30 minutos cada, totalizando <b>{(mentoriaInfo.encontros * 1.5).toString().padStart(2, '0')} HORAS</b>.
                                            Eles ocorrerão sempre das <b>{mentoriaInfo.stringhorario}</b>, nas seguintes datas: <br />
                                            {!mentoriaDatasPorId ? <CarregamentoWidget /> :
                                                <ul>
                                                    {mentoriaDatasPorId.map((datas) => {
                                                        return (
                                                            <li>
                                                                <b>{datas.linhadata}</b>
                                                            </li>
                                                        )
                                                    })}
                                                </ul>
                                            }
                                        </p>
                                    </div>
 */}
                                    <input type='checkbox' id='3'></input>
                                    <label htmlFor='3'><h4>Qual é o total de mentoria individual?</h4> <FontAwesomeIcon icon={faPlus} color='#6F8190' fontSize='20px' /></label>
                                    <div className='resposta'>
                                        <p>
                                            O aluno tem direito a <b>{mentoriaInfo.mentorias.toString().padStart(2, '0')} HORA</b> de mentoria individualizada.
                                            A data e horário deverão ser combinados entre professor e aluno.
                                            O período máximo para este agendamento é de <b>{mentoriaInfo.telegram.toString().padStart(2, '0')} MESES</b> após
                                            o início da turma.
                                        </p>
                                    </div>

                                    {/*                                     <input type='checkbox' id='4'></input>
                                    <label htmlFor='4'><h4>Quando será a mentoria individual?</h4> <FontAwesomeIcon icon={faPlus} color='#6F8190' fontSize='20px' /></label>
                                    <div className='resposta'>
                                        <p>
                                            A data e horário deverão ser combinados entre professor e aluno.
                                            O período máximo para este agendamento é de <b>{mentoriaInfo.telegram.toString().padStart(2, '0')} MESES</b> após
                                            o início da turma.
                                        </p>
                                    </div> */}

                                    <input type='checkbox' id='5'></input>
                                    <label htmlFor='5'><h4>Até quando eu posso assistir às gravações?</h4> <FontAwesomeIcon icon={faPlus} color='#6F8190' fontSize='20px' /></label>
                                    <div className='resposta'>
                                        <p>
                                            Enquanto tiver um plano ativo no Provas de TI. Todas as aulas gravadas por ocasião deste curso,
                                            além de todas as <b>{numeros.numero_aulas}</b> aulas do Provas de TI, poderão
                                            ser acessadas pelo período do seu acesso ao Plano Anual.
                                        </p>
                                    </div>

                                    {mentoriaInfo.discursivas === 0 ?
                                        ""
                                        :
                                        <>
                                            <input type='checkbox' id='6'></input><label htmlFor='6'><h4>Até quando eu posso enviar as discursivas?</h4> <FontAwesomeIcon icon={faPlus} color='#6F8190' fontSize='20px' /></label><div className='resposta'>
                                                <p>
                                                    Quando a mentoria envolve discursivas, elas devem ser enviadas - preferencialmente - enquanto estiverem acontecendo os {mentoriaInfo.encontros.toString().padStart(2, '0')} encontros via 
                                                    Zoom. <b>Após o último encontro via Zoom</b>, os alunos atrasados ainda terão <b>{mentoriaInfo.telegram.toString().padStart(2, '0')} MESES</b> para enviar os textos e marcar a mentoria individual. 
                                                    Após este período, não serão mais recebidos textos para correção. Esta mentoria dá direito a <b>{mentoriaInfo.discursivas.toString().padStart(2, '0')}</b> discursivas.
                                                </p>
                                            </div>
                                        </>
                                    }
                                    <input type='checkbox' id='7'></input>
                                    <label htmlFor='7'><h4>Como eu vou acessar as gravações?</h4> <FontAwesomeIcon icon={faPlus} color='#6F8190' fontSize='20px' /></label>
                                    <div className='resposta'>
                                        <p>
                                            As aulas do Provas de TI, assim como as gravações que ocorrerão às <b>{mentoriaInfo.diadasemana}</b>,
                                            serão assistidas dentro da área de alunos do Provas de TI, que é chamada de Nutror.
                                            Após a aquisição, você receberá um e-mail com os dados de acesso ao Nutror.
                                        </p>
                                    </div>

                                    <input type='checkbox' id='8'></input>
                                    <label htmlFor='8'><h4>Por quanto tempo existirá o grupo de telegram?</h4> <FontAwesomeIcon icon={faPlus} color='#6F8190' fontSize='20px' /></label>
                                    <div className='resposta'>
                                        <p>
                                            O Prof. coordenará o grupo de Telegram pelo período de <b>{mentoriaInfo.telegram.toString().padStart(2, '0')} MESES</b>.
                                            Após este período, o grupo poderá ser desfeito pelo professor
                                            (sendo opcional a continuação da sua existência e a sua manutenção).
                                        </p>
                                    </div>

                                    <input type='checkbox' id='9'></input>
                                    <label htmlFor='9'><h4>Quais as formas de pagamento?</h4> <FontAwesomeIcon icon={faPlus} color='#6F8190' fontSize='20px' /></label>
                                    <div className='resposta'>
                                        <p>
                                            Você pode pagar utilizando <b>PIX, CARTÃO, PAYPAL ou Boleto</b>.
                                            Ao clicar no botão de pagamento você será levado a uma tela na
                                            qual as opções de cartão, parcelamento etc. poderão ser visualizadas e alteradas.
                                        </p>
                                    </div>

                                    <input type='checkbox' id='10'></input>
                                    <label htmlFor='10'><h4>Assinante do Provas de TI tem desconto?</h4> <FontAwesomeIcon icon={faPlus} color='#6F8190' fontSize='20px' /></label>
                                    <div className='resposta'>
                                        <p>
                                            <b>Sim</b>. PORÉM, ao optar por pagar com desconto, estamos considerando
                                            a mentoria do professor, que é o produto principal do pacote,
                                            <b>{mentoriaInfo.bonusvip === 0 ? " SEM bônus de acréscimo" : " com bônus de acréscimo MENOR"}</b>.
                                            Caso queira somar o bônus completo, opte pelo pagamento SEM o desconto.
                                        </p>
                                    </div>

                                    <input type='checkbox' id='11'></input>
                                    <label htmlFor='11'><h4>Alunos novos acessam todas as aulas do Provas de TI?</h4> <FontAwesomeIcon icon={faPlus} color='#6F8190' fontSize='20px' /></label>
                                    <div className='resposta'>
                                        <p>
                                            <b>Sim</b>. O Plano Anual dá acesso à área de alunos - o Nutror - onde <b>TODAS</b> as aulas em vídeo usadas nos Planos de Estudo são disponibilizadas.
                                            São mais de <b>{numeros.numero_planos}</b> planos de estudo por edital, <b>{numeros.numero_cursos}</b> cursos, <b>{numeros.numero_modulos}</b> módulos
                                            e <b>{numeros.numero_aulas}</b> aulas em vídeo. Veja todos os cursos listados <a href='/cursos/todos' target="_blank">aqui</a>.
                                        </p>

                                    </div>

                                    <input type='checkbox' id='12'></input>
                                    <label htmlFor='12'><h4>Assinante do Provas de TI tem acréscimo no plano vigente?</h4> <FontAwesomeIcon icon={faPlus} color='#6F8190' fontSize='20px' /></label>
                                    <div className='resposta'>
                                        <p>
                                            <b>{mentoriaInfo.bonusvip === 0 ? "NÃO" : "SIM, porém MENOR"}</b>. Ao optar por pagar com desconto, estamos considerando
                                            a mentoria do professor, que é o produto principal do pacote,
                                            <b>{mentoriaInfo.bonusvip === 0 ? " SEM BÔNUS de acesso" : " e um BÔNUS de acesso MENOR"}</b>.
                                            Caso queira somar o bônus completo, opte pelo pagamento SEM o desconto.
                                        </p>
                                    </div>

                                    <input type='checkbox' id='13'></input>
                                    <label htmlFor='13'><h4>Posso retirar algum item do combo?</h4> <FontAwesomeIcon icon={faPlus} color='#6F8190' fontSize='20px' /></label>
                                    <div className='resposta'>
                                        <p>
                                            Pode, mas isso inviabiliza os descontos. O objetivo de unir vários
                                            itens em um pacote é compartilhar custos e diminuir o valor total.
                                            Você pode adquirir itens individualmente, mas pagando valor integral.
                                            A ideia é que o pacote seja mais vantajoso para os envolvidos.
                                            O aluno deve analisar as condições colocadas e decidir aderir (ou não)
                                            da forma que elas foram apresentadas. Em caso de dúvida, entre em contato.
                                        </p>
                                    </div>

                                    <input type='checkbox' id='14'></input>
                                    <label htmlFor='14'><h4>Por que não há uma opção para o meu caso?</h4> <FontAwesomeIcon icon={faPlus} color='#6F8190' fontSize='20px' /></label>
                                    <div className='resposta'>
                                        <p>
                                            Se houvesse "um pacote para cada caso", isso eliminaria o compartilhamento
                                            de custos de gerenciamento, marketing, horário de professores etc.
                                            Como consequência, não haveria os mesmos descontos pensados originalmente.
                                            Em resumo, não é possível desmontar um pacote com vários itens e, ainda assim,
                                            ter os mesmos descontos. Ou uma coisa, ou outra.
                                        </p>
                                    </div>
                                </div>

                                <div className="whatsapp">
                                    <FontAwesomeIcon className="icon" icon={faWhatsapp} />
                                    <p className="title">WhatsApp</p>

                                    <h4>(15) 9 9112 5845</h4>

                                    <a href="https://api.whatsapp.com/send/?phone=5515991125845&text&app_absent=0" target='_blank' rel="noreferrer">
                                        <button className="button btn-1">Falar por Whatsapp</button>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className="footer">
                            <p>
                                <ul>
                                    <li><FontAwesomeIcon icon={faAngleRight} />
                                        <a href="/" target="_blank">
                                            Home do Provas de TI
                                        </a>
                                    </li>
                                    <li><FontAwesomeIcon icon={faAngleRight} />
                                        <a href="/planosdeassinatura" target="_blank">
                                            Assine o Provas de TI
                                        </a>
                                    </li>
                                    <li><FontAwesomeIcon icon={faAngleRight} />
                                        <a href="/mentorias" target="_blank">
                                            Todas as Mentorias
                                        </a>
                                    </li>
                                </ul>
                            </p>
                            <p>
                                LW Tecnologia - 29.689.900/0001-70 - Provas de TI © {anoAtual}
                            </p>
                            <p>
                                <a href="https://t.me/profwaltercunha" target='_blank' rel="noreferrer">
                                    <FontAwesomeIcon className="icon" icon={faTelegram} />
                                </a>

                                <a href="https://www.facebook.com/tiparaconcursos/" target='_blank' rel="noreferrer">
                                    <FontAwesomeIcon className="icon" icon={faFacebookF} />
                                </a>

                                <a href="https://www.youtube.com/user/portalprovas" target='_blank' rel="noreferrer">
                                    <FontAwesomeIcon className="icon" icon={faYoutube} />
                                </a>

                                <a href="https://twitter.com/timasters" target='_blank' rel="noreferrer">
                                    <FontAwesomeIcon className="icon" icon={faTwitter} />
                                </a>

                                <a href="https://www.instagram.com/provasdeti/" target='_blank' rel="noreferrer">
                                    <FontAwesomeIcon className="icon" icon={faInstagram} />
                                </a>

                                <a href="https://groups.google.com/g/timasters-google?pli=1" target='_blank' rel="noreferrer">
                                    <FontAwesomeIcon className="icon" icon={faEnvelope} />
                                </a>
                            </p>
                        </div>

                        <div className="backTop" id="backTop" onClick={() => { window.scrollTo({ top: 0, behavior: 'smooth' }) }}>
                            <FontAwesomeIcon icon={faArrowUp} />
                        </div>
                    </div>
                </LandingMentoriaStyle>}
        </>
    )
}

export default LandingMentoria;